[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="mode-light"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  --tootip-bg: #434343;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --text-color-fourth: #adaeb4;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue1: #225085;
  --blue2: #507dc9;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  /*---------- app asset colors ----------*/
  --app-asset-bg: #1b1b1b;
  --app-legend-bg: #8c8c8c;
  --app-text-color: #8f8f8f;
  --app-timepicker-bg: #454545;
  /*---------- chart colors ----------*/
  --tooltip-bg: rgba(47, 51, 66, 0.91);
  --statistic-color: #bcbcbc;
  --depth-color: #61688a;
  --depth-tip-color: #cad7e0;
  --depth-bg-color: rgba(0, 0, 0, 0.8);
  --depth-bidsLineColor: #41b37d;
  --depth-asksLineColor: #d74e5a;
  --depth-axisColor: rgba(97, 104, 138, 0.3);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7a52f4;
  --saas-main-color: #4e2c5b;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #bc20fe;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ed3ee;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #a833cc;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #cfbfff;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #4a4c51;
  --login-bg2: #2e1b3f;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
[data-theme="summer-capital-mode"] {
  --main-bg-color: #000;
  --main-color: #d9a527;
  --saas-main-color: #693a0f;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #f8fafc;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030e;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4a4a4a;
  --saas-table-btn-border1: #d9a527;
  --saas-table-btn-bg1: rgba(217, 165, 39, 0.2);
  --saas-table-btn-border2: #eb892e;
  --saas-table-btn-bg2: rgba(235, 137, 46, 0.2);
  --saas-product-label-bg1: #daa729;
  --saas-product-label-bg2: #693a10;
  --saas-investor-label-bg: rgba(217, 165, 39, 0.2);
  --saas-favorite-bg2: #4bbedc;
  --saas-download-color: #ffdd8c;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31b6d1;
  --saas-investor-not-read-color: #ffe7ac;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #aaa8aa;
  --saas-table-th-bg: #262628;
  --saas-contact-textarea-border-color: #4e4e4e;
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #ff0100;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --login-bg1: #6c3500;
  --login-bg2: #201102;
  --market-thead-bg: #262628;
  --editor-menu-border-color: #e5e7eb;
  --editor-a-color: #1677ff;
  --editor-a-hover-color: #69b1ff;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__5PynW4ZJ {
  max-width: 1200px;
  background: var(--module-bg);
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.__5PynW4ZJ .TjKhD79o {
  display: flex;
  justify-content: space-between;
}
.__5PynW4ZJ .TjKhD79o .lSPhFOTU {
  color: var(--white);
  width: 170px;
  height: 48px;
  font-size: 14px;
  text-align: center;
  padding: 0 !important;
  border: none;
}
.__5PynW4ZJ .yHYEQg98 {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-thead > tr > th {
  padding: 6px 14px;
  height: 57px;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-tbody > tr > td {
  padding: 16px 12px;
  height: 57px;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-thead > tr > th,
.__5PynW4ZJ .yHYEQg98 .ant-table-sticky-holder {
  background-color: var(--table-heading-bg);
}
.__5PynW4ZJ .__937GqTEu {
  height: 64px;
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: var(--CommonRegular);
  text-align: center;
  color: var(--text-color);
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-btn:hover {
  color: var(--white);
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-active {
  background-color: var(--tab-active-bg);
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.__5PynW4ZJ .AMD3V2IE {
  padding: 25px 24px 32px;
  min-height: 600px;
  background: var(--tab-active-bg);
}
.__5PynW4ZJ .AMD3V2IE .TWfqAIOj {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.__5PynW4ZJ .AMD3V2IE .TWfqAIOj:hover {
  color: var(--main-text-hover-color);
}
.__5PynW4ZJ .AMD3V2IE .__2B8V2yJy {
  margin-bottom: 30px;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  text-align: center;
  border: none;
  transition: unset;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.__5PynW4ZJ .AMD3V2IE .Bp953wVN {
  display: flex;
  margin-bottom: 15px;
}
.__5PynW4ZJ .AMD3V2IE .Bp953wVN .JnAAm6qt {
  z-index: 99;
  width: 42px;
  cursor: pointer;
}
.__5PynW4ZJ .AMD3V2IE .Bp953wVN .JnAAm6qt:hover {
  color: var(--main-text-hover-color);
}
.__5PynW4ZJ .AMD3V2IE .Bp953wVN .PITGURwm {
  flex: 1;
  font-size: 16px;
  text-align: center;
  margin-left: -42px;
  font-family: var(--CommonMedium);
}
.__5PynW4ZJ .AMD3V2IE .smvVQFc8 {
  width: 80px;
  height: 28px;
}
.__5PynW4ZJ .AMD3V2IE .jVJ5xfL5 {
  width: 80px;
  height: 28px;
  color: var(--text-color-second) !important;
  border: 1px solid var(--btn-border) !important;
}
.__5PynW4ZJ .AMD3V2IE .jVJ5xfL5:hover {
  background-color: transparent !important;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}
.__5PynW4ZJ .AMD3V2IE .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.__5PynW4ZJ .AMD3V2IE .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.__5PynW4ZJ .AMD3V2IE .ant-btn:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.__5PynW4ZJ .Ag1sum2d {
  color: var(--main-color);
  cursor: pointer;
}
._weWCbHu .JrZ60ka0 .Xa3D_7gC {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
._weWCbHu .JrZ60ka0 .Xa3D_7gC .guBbAHO1 {
  color: var(--text-color-second);
}
._weWCbHu .JrZ60ka0 .Xa3D_7gC .ZO4p4Pz7 {
  color: var(--white);
}
._weWCbHu .__2B8V2yJy .ant-table-thead > tr > th {
  padding: 6px 14px;
  height: 48px;
  background-color: var(--table-heading-bg);
  border-bottom: none;
}
._weWCbHu .__2B8V2yJy .ant-table-tbody > tr > td {
  padding: 16px 12px;
  height: 57px;
  color: var(--white);
}
._weWCbHu .__2B8V2yJy .ant-table {
  background-color: var(--main-bg-color);
}
._weWCbHu .__2B8V2yJy .ant-table-content {
  min-height: 0px;
}
.__6SmQOOgN .ant-form-item-control-input .ant-input {
  background: var(--module-bg);
}
.__6SmQOOgN .ant-form-item-control-input-content {
  background: transparent;
}
.__6SmQOOgN .ant-modal-body {
  margin-bottom: 48px;
}
.__6SmQOOgN .ant-modal-footer {
  display: flex;
  border-top: none;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.__6SmQOOgN .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.__6SmQOOgN .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.__6SmQOOgN .ant-modal-footer .ant-btn-primary {
  background: var(--main-color);
}
.__6SmQOOgN .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
  border: none;
}
.__6SmQOOgN .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.__6SmQOOgN .XDex7XzY :not(.xuzQyBaC).zy9EW1lx,
.__6SmQOOgN .XDex7XzY :not(.__7tzlW_1O).aby6Nuw8,
.__6SmQOOgN .XDex7XzY :not(.xuzQyBaC).zy9EW1lx:hover,
.__6SmQOOgN .XDex7XzY :not(.__7tzlW_1O).aby6Nuw8:hover {
  background: var(--module-bg);
}
._LafabGV {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
._LafabGV .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  height: 32px;
  text-align: center;
  border: none;
  transition: unset;
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.ant-table-tbody > tr > td {
  padding: 16px 10px !important;
}
tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
  background: var(--table-expanded-bg);
}
.ant-table-expanded-row .ant-table-expanded-row-level-1 {
  background: transparent !important;
}
.__2v0bEp0n {
  display: flex;
  justify-content: space-between;
  margin: 0;
  line-height: 22px;
}
.__2v0bEp0n .__2IY8rET9 {
  flex: 0 0 40%;
}
.__2v0bEp0n .cf2TOH_w {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.__2v0bEp0n .cf2TOH_w .__05O8Dfee {
  justify-content: flex-end;
}
.__2v0bEp0n ._0r2K3dD {
  flex: 1 1 auto;
}
.__2v0bEp0n .__05O8Dfee {
  display: flex;
}
.__2v0bEp0n .__05O8Dfee .__4NaxxPCd {
  flex: 0 0 120px;
  margin-right: 26px;
}
.__2v0bEp0n .__05O8Dfee .h3o1htRV {
  flex: 0 0 150px;
  text-align: right;
}
.ncTOz22r {
  cursor: pointer;
  width: 70px;
  display: flex;
  justify-content: flex-end;
  color: var(--main-color);
}
.ncTOz22r .tngAqTPu {
  margin-left: 8px;
  margin-top: 9px;
  width: 6px;
  height: 4px;
}

